import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Box, Button, Dialog, DialogTitle } from '@material-ui/core';
// import styles from './blog.module.css';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { styled } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';

const windowInnerWidth = window.innerWidth;

const useStyles = makeStyles({
  blogBox: {
    padding: '24px'
  },
  dateText: {
    float: 'right',
    fontWeight: 300,
    fontSize: '15px'
  },
  titleText: {
    fontWeight: 600,
    fontSize: '20px',
    textWrap: 'nowrap',
    maxWidth: `${windowInnerWidth - 136}px`,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  contentText: {
    fontSize: '20px'
  },
  card: {
    // maxWidth: 345,
    marginBottom: '10px',
    borderRadius: '16px',
    backgroundColor: '#E4E6C2'
  },
  addButton: {
    height: '40px',
    width: '40px',
    color: '#525B91'
  },
  searchGrid: {
    marginBottom: '15px'
  },
  blogsContainer: {
    height: '80vh',
    overflow: 'auto'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
    margin: 'auto',
    marginTop: '10px'
  },
  textField: {
    marginBottom: '10px'
  },
  button: {
    marginTop: '10px',
    width: '30%',
    alignSelf: 'center',
  },
  search: {
    '& .MuiOutlinedInput-input': {
      padding: '10px', // Example of overriding padding
      // Add more styles as needed
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -5px) scale(0.75)'
    }
  },
  createBlogForm: {
    '& .MuiDialog-paperWidthSm': {
      width: "100%",
      height: "70%"
    }
  },
  moreMenu: {
    '& .MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded': {
      right: 0,
      height: "70%"
    }
  }
});

const CustomMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px', // Custom border radius
    boxShadow: '0 4px 10px rgba(0,0,0,0.2)', // Custom shadow
    width: '40%',
    marginLeft: '50%',
    top: '120px !important',
  },
}));

function BlogsContainer(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [blogs, setBlogs] = useState([]);
  
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState('');
  const [showMyBlogs, setShowMyBlogs] = useState(false)

  useEffect(() => {
    let url = `${process.env.REACT_APP_RAILS_API}/api/v1/blogs`
    if (showMyBlogs) {
      url = `${process.env.REACT_APP_RAILS_API}/api/v1/users/${props.user.id}/blogs`
    }
    console.log(props.user)
    const fetchBlogs = async () => {
        try {
        const response = await axios.get(url);
        setBlogs(response.data);
        } catch (error) {
        console.error('Error fetching blogs:', error);
        }
    };

    fetchBlogs();
  }, [open, showMyBlogs]);

  const handleClose = () => {
    setOpen(!open);
  };

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    const formData = new FormData();
    formData.append('blog[title]', title);
    formData.append('blog[content]', content);
    if (image) {
      formData.append('blog[image]', image);
    }
    const response = await axios.post(`${process.env.REACT_APP_RAILS_API}/api/v1/users/${props.user.id}/blogs`, formData, {
      headers: {
        'Accept': 'application/json',
        // Content-Type is automatically set for FormData, so you can omit it
      },
    });

    // Handle the response...
    setOpen(!open);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Set the file object
  };

  const options = [
    showMyBlogs ? 'Show All Blogs' : 'Show My Blogs'
  ];
  
  const ITEM_HEIGHT = 48;
  
  function MoreMenu() {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
      console.log("handleClick")
      setOpen(!open)
    };
    const handleClose = () => {
      console.log("handleClose")
      setOpen(!open)
      setShowMyBlogs(!showMyBlogs)
    };
  
    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <CustomMenu
          className={classes.moreMenu}
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          open={open}
          onClose={handleClose}
        >
          {options.map((option) => (
            <MenuItem key={option} onClick={handleClose}>
              {option}
            </MenuItem>
          ))}
        </CustomMenu>
      </div>
    );
  }

  function handleBlogClick(id) {
    navigate(`/blog/${id}`);
  }

  function showAllBlogs() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid className={classes.searchGrid} item xs={8}>
            <TextField className={classes.search} id="outlined-basic" label="Search Blog" variant="outlined" />
          </Grid>
          <Grid item xs={2}>
            {MoreMenu()}
          </Grid>
          <Grid item xs={2}>
            <AddBoxIcon className={classes.addButton} onClick={() => setOpen(!open)} />
          </Grid>
        </Grid>
        <div className={classes.blogsContainer}>
          {blogs.map(blog => {
            // const date = new Date(blog.updated_at); // Current date and time
            const fullImageUrl = `${process.env.REACT_APP_RAILS_API}${blog.image_url}`;
            return (
              <Card key={blog.id} className={classes.card} onClick={() => handleBlogClick(blog.id)}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                      DV
                    </Avatar>
                  }
                  title={
                    <Typography className={classes.titleText} variant="body2" color="text.secondary">
                      {blog.title}
                    </Typography>
                  }
                />
                <CardMedia
                  component="img"
                  height="194"
                  image={fullImageUrl}
                  alt="Image"
                />
                <CardContent>
                  <Typography className={classes.contentText} variant="body2" color="text.secondary">
                    {blog.content.slice(0, 100)}..
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                  </IconButton>
                  <IconButton aria-label="share">
                    <ShareIcon />
                  </IconButton>
                </CardActions>
              </Card>
            )
          })}
        </div>
        <Dialog onClose={handleClose} open={open} className={classes.createBlogForm}>
          <DialogTitle>Create a Blog Post</DialogTitle>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              className={classes.textField}
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <TextField
              className={classes.textField}
              label="Content"
              variant="outlined"
              multiline
              rows={6}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
            />
            {/* <TextField
              className={classes.textField}
              label="Image URL"
              variant="outlined"
              value={image}
              onChange={(e) => setImage(e.target.value)}
            /> */}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange} // Handle file change
              required // Optional: make this required
            />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
            >
              Publish
            </Button>
          </form>
        </Dialog>
      </>
    )
  }

  return (
    <Box className={classes.blogBox}>
      {showAllBlogs()}
    </Box>
  );
}

export default BlogsContainer